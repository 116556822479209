
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './ConsultButton.scss';

const ConsultButton = ({ bg, color, style, ...props }) => {
	const { t } = useTranslation();

	return (
		<Link
			className="consult-btn"
			to="consult"
			style={{
				backgroundColor: bg,
				color: color,
				fontSize: props.fs || "1em",
				...style
			}}>{t('make_appointment_btn')}</Link>
	)
}

export default ConsultButton