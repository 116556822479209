import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';
import './NotFound.scss';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
	const { t } = useTranslation();

	return (
		<>
			<Helmet>
				<title>{t('page_not_found')}</title>
			</Helmet>
			<div className='notfound'>
				<span>404</span>
				<p>{t('page_not_found')}</p>
				<div className='gohome'>
					<FontAwesomeIcon icon={faArrowLeft} />
					<a href="/">{t('gohome')}</a>
				</div>
			</div>
		</>
	)
}

export default NotFound