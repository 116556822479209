import Logo from '../../logo.svg';
import { ConsultButton, ChangeLanguage } from '../';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './Header.scss';

const Header = () => {
	const { t } = useTranslation();

	return (
		<header>
			<Link to="/" className="logo-wrap">
				<img src={Logo} alt="logo" />
				<div>
					<span className='name'>Sergej</span>
					<span className='name'>Heilsbringer</span>
					<span>{t('motto')}</span>
				</div>
			</Link>
			<div>
				<ChangeLanguage/>
				<ConsultButton bg="var(--green)" color="var(--blue)" />
			</div>
		</header>
	)
}

export default Header;
