import { ConsultButton } from '../';
import { useTranslation } from 'react-i18next';

import './Main.scss';

const Main = () => {
	const { t } = useTranslation();
	
	return (
		<section className='main'>
			<h1 className='name text-center'>{t('tired_of_failures')}</h1>
			<div className='quotes'>
				<span className='question'>{t('intro')}</span>
			</div>
			<span className='answer'>{t('start_change')}</span>
			<ConsultButton bg="var(--blue-500)" color="var(--white)" fs="1.1em" />
		</section>
	)
}

export default Main;