import { useTranslation } from 'react-i18next';
import './Footer.scss';

const Footer = () => {
	const { t } = useTranslation();

	return (
		<div className='footer'>
			<span>© 2024 Sergej Heilsbringer.</span>
			<span>{t('all_rights_reserved')}</span>
			<a target="_blank" href="/Privacy_Policy.pdf">{t('privacy')}</a>
		</div>
	)
}

export default Footer