import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { AboutMe, AboutProgram, Certificates, Contact, Description, Footer, Header, Main, Results, Reviews, ToTopButton } from '../../Components';

const Home = () => {
	const { t } = useTranslation();

	return (
		<HelmetProvider>
			<Helmet>
				<title>Sergej Heilsbringer</title>
				<meta name="description" content={t('site_description')} />
			</Helmet>
			<Header />
			<Main />
			<Description />
			<Results />
			<AboutProgram />
			<AboutMe />
			<Reviews />
			<Certificates />
			<Contact />
			<Footer />
			<ToTopButton offset={200} />
		</HelmetProvider>
	)
}

export default Home;