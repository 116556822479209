import { useTranslation } from 'react-i18next';
import { ConsultButton } from '../';
import Person from '../../img/person1.png';

import './Description.scss';

const Description = () => {
	const { t } = useTranslation();

	return (
		<section className='description'>
			<h1 className='text-center'>{t('this_is_about_you')}</h1>
			<div className="content">
				<div className="img-container">
					<img src={Person} alt="person" />
				</div>
				<div className="text-container">
					<span>{t('chasing')}</span>
					<span>{t('at_some_point')}</span>
					<span>{t('you_are_desperate')}</span>
					<span>{t('consultation')}</span>
				</div>
			</div>
			<ConsultButton
				bg={"var(--green)"}
				color={"var(--blue)"}
			/>
		</section>
	)
}

export default Description