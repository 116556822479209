import { useTranslation } from 'react-i18next';
import { YoutubeEmbed } from '../';
import {MyCarousel} from '../';

import './Reviews.scss';

const Reviews = () => {

	const ids = [
		"pZzHLwRk5hc",
		"iZJcmhbVDs8",
		"fLOWg9Rz1sc",
		"eaIBWgyX-Cc"
	]

	const { t } = useTranslation();
	return (
		<section className='reviews'>
			<h1 className='text-center'>{t('reviews')}</h1>
			<div className="content">
				<MyCarousel>
					{ids.map(
						id => <YoutubeEmbed key={id} id={id} />
					)}
				</MyCarousel>

				<span>{t('more_reviews')} <a target='_blank' rel="noreferrer" href='https://www.youtube.com/@sergejlastowski3242'>{t('YouTube_channel')}</a></span>
			</div>
		</section>
	)
}

export default Reviews