import { useTranslation } from 'react-i18next';
import Addiction from '../../img/addiction.png';
import EmotionalControl from '../../img/emotions.png';
import Fears from '../../img/fears.png';
import Goals from '../../img/goals.png';
import FinancialGrowth from '../../img/money.png';
import Motivation from '../../img/motivation.png';
import Relationship from '../../img/relationship.png';
import Sleep from '../../img/sleep.png';

import './Results.scss';

const Results = () => {
	const { t } = useTranslation();

	return (
		<section className='results'>
			<h1 className='text-center'>{t('after_my_program')}</h1>
			<div className='grid-container'>
				<div className='item'>
					<img alt="motivation" src={Motivation} />
					<span>{t('motivation')}</span>
				</div>
				<div className='item'>
					<img alt="goals" src={Goals} />
					<span>{t('goals')}</span>
				</div>
				<div className='item'>
					<img alt="finantial growth" src={FinancialGrowth} />
					<span>{t('growth')}</span>
				</div>
				<div className='item'>
					<img alt="release from fears" src={Fears} />
					<span>{t('fears')}</span>
				</div>
				<div className='item'>
					<img alt="emotional control" src={EmotionalControl} />
					<span>{t('emotional_control')}</span>
				</div>
				<div className='item'>
					<img alt="release from addictions" src={Addiction} />
					<span>{t('bad_habits')}</span>
				</div>
				<div className='item'>
					<img alt="relationship" src={Relationship} />
					<span>{t('relationships')}</span>
				</div>
				<div className='item'>
					<img alt="sleep" src={Sleep} />
					<span>{t('sleep')}</span>
				</div>
			</div>
		</section>
	)
}

export default Results;