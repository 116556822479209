import { faArrowLeft, faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { render } from '@react-email/render';
import { ConsultRequest } from '../../emails';

import './Consult.scss';

const Consult = () => {
	const { t, i18n } = useTranslation();

	const [loading, setLoading] = useState(false);
	const [sent, setSent] = useState(null);

	const form = useRef();
	const name = useRef();
	const phone = useRef();
	const email = useRef();
	const message = useRef();

	const sendEmail = async (e) => {

		const body = {
			from: process.env.REACT_APP_emailFrom,
			to: process.env.REACT_APP_emailTo,
			subject: 'Sie haben eine neue Anfrage',
			html: render(<ConsultRequest name={name.current.value} language={i18n.language} phone={phone.current.value} email={email.current.value} message={message.current.value} />)
		}

		fetch('/.netlify/functions/send-email', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(body)
		}).then(
			(res) => {
				if (res.status === 200)
					setSent(true);
				else
					setSent(false);
			},
			(error) => { setSent(false); }
		).finally(() => {
			setLoading(false);
			e.target.reset();
		});
	}

	const onSubmit = e => {
		e.preventDefault();

		if (name.current?.value && phone.current?.value && email.current?.value) {
			setLoading(true);
			sendEmail(e);
		}
	}

	return (
		<>
			<Helmet>
				<title>{t('make_appointment_title')}</title>
				<meta name="description" content={t('appointment_description')} />
			</Helmet>
			<div className='consult'>
				{sent === true
					?
					<div className='sent'>
						<FontAwesomeIcon icon={faCheck} />
						<div>
							<span>{t('sent')}</span>
							<Link to="/">{t('gohome')}</Link>
						</div>
					</div>
					: sent === false
						?
						<div className='not-sent'>
							<FontAwesomeIcon icon={faXmark} />
							<div>
								<span>{t('sth_went_wrong')}</span>
								<span>{t('try_later')}</span>
								<Link to="/">{t('gohome')}</Link>
							</div>
						</div>
						:
						<form onSubmit={onSubmit} ref={form}>
							<Link to="/" className='gohome'>
								<FontAwesomeIcon icon={faArrowLeft} />
								<span>{t('gohome')}</span>
							</Link>
							<h2 className='text-center'>{t('make_appointment_form')}</h2>
							<div className='form-body'>
								<input ref={name} required placeholder={`${t('name')}*`} name="name" type="text" />
								<input ref={phone} required placeholder={`${t('phone')}*`} name="phone" type="text" />
								<input ref={email} required placeholder={`${t('email')}*`} name="email" type="email" />
								<textarea ref={message} placeholder={t('your_question')} name="message"></textarea>
								{loading
									? <div className="loading"><div className='spinner'></div></div>
									: <button type="submit">{t('send')}</button>
								}
							</div>
						</form>
				}
			</div>
		</>
	)
}

export default Consult