import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import de from '../locales/de.json';
import ru from '../locales/ru.json';

i18n
	.use(initReactI18next)
	.init({
		resources: {
			ru: {
				translation: ru
			},
			de: {
				translation: de
			}
		},
		lng: localStorage.getItem('lang') || "ru",
		fallbackLng: "ru",
		interpolation: {
			escapeValue: false
		}
	});

export default i18n;