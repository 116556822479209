import PropTypes from "prop-types";
import './YoutubeEmbed.scss';

const YoutubeEmbed = ({ id }) => (
	<div className="video-responsive">
		<iframe
			src={`https://www.youtube.com/embed/${id}`}
			allowFullScreen
			title="Embedded youtube"
		/>
	</div>
);

YoutubeEmbed.propTypes = {
	id: PropTypes.string.isRequired
};

export default YoutubeEmbed;