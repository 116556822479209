import { useTranslation } from 'react-i18next';
import { ConsultButton } from '..';
import avatar from '../../img/avatar.jpg';

import './AboutMe.scss';

const About = () => {
	const { t } = useTranslation();

	return (
		<section className="about">
			<h1 className='text-center'>{t('about_me')}</h1>
			<div className='content'>
				<div className="img-container">
					<img src={avatar} alt="avatar" />
				</div>
				<div className="text-container">
					<span style={{ fontSize: "1.1em", fontWeight: 700 }}>{t('hello')}</span>
					<span>{t('i_am_sergej')}</span>
					<span>{t('my_task')}</span>
					<span>{t('i_give')} <span style={{ fontWeight: 700 }}>{t('guarantee')}</span> {t('on_result')}</span>
				</div>
			</div>
			<ConsultButton
				bg={"var(--green)"}
				color={"var(--blue)"}
			/>
		</section>
	)
}

export default About;