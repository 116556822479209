import { useEffect, useState } from 'react';

import './Preloader.scss';

const Preloader = ({ children }) => {
	const [loading, setLoading] = useState(true);
	const [remove, setRemove] = useState(false);

	useEffect(() => {
		const handleLoad = () => {
			setLoading(false);
		};

		if (document.readyState === "complete")
			handleLoad();
		else {
			window.addEventListener('load', handleLoad);
		}

		return () => {
			document.removeEventListener('load', handleLoad);
			window.removeEventListener('load', handleLoad)
		};
	}, []);

	const handleRemove = () => {
		if (!loading) setRemove(true);
	}

	return (
		<>
			{!remove &&
				<div className={`preloader ${loading ? '' : 'ready'}`} onTransitionEnd={handleRemove}>
					<div className="spinner"></div>
				</div>
			}
			{children}
		</>
	)
}

export default Preloader