import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import deFlag from '../../img/flags/de.svg';
import ruFlag from '../../img/flags/ru.svg';
import './ChangeLanguage.scss';

const ChangeLanguage = () => {

	const { i18n } = useTranslation();
	const langModal = useRef();
	const langBtn = useRef();

	const toggleModal = e => {
		langModal.current?.classList.toggle("visible");
	}

	const setHighlighting = lang => {
		if (langModal.current) {
			const btns = Array.from(langModal.current.children);
			btns.forEach(btn => {
				btn.removeAttribute("active");
				if (btn.getAttribute('lang') === lang) {
					btn.setAttribute("active", true);
				}
			})
		}
	}

	const changeLang = (e) => {
		const lang = e.currentTarget.getAttribute('lang');
		if (lang) {
			localStorage.setItem('lang', lang);
		}
		window.location.reload();
	}

	const handleClickOutside = (event) => {
		if (langModal.current && !langModal.current.contains(event.target) && !langBtn.current.contains(event.target)) {
			langModal.current.classList.remove("visible");
		}
	};

	useEffect(() => {
		const lang = localStorage.getItem('lang') || i18n.languages[0]
		setHighlighting(lang);
		document.addEventListener('click', handleClickOutside, true);

		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className='change-language'>
			<button ref={langBtn} onClick={toggleModal} className='change-language-icon'>
				<FontAwesomeIcon style={{ color: "#224e77" }} icon={faGlobe} />
			</button>
			<div className="modal-wrap" ref={langModal}>
				<button lang="de" onClick={changeLang} className='item'>
					<img src={deFlag} alt="de" />
					<span>Deutsch</span>
				</button>
				<button lang="ru" onClick={changeLang} className='item'>
					<img src={ruFlag} alt="ru" />
					<span>Русский</span>
				</button>
			</div>
		</div>
	)
}

export default ChangeLanguage;