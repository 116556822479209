import { useTranslation } from 'react-i18next';
import { MyCarousel } from '../';

import AAoH from '../../img/certificates/american_academy_of_hypnosis.jpg';
import HMTA from '../../img/certificates/holistic_mid_therapy_association.jpg'

import './Certificates.scss';

const Certificates = () => {
	const { t } = useTranslation();

	return (
		<section className='certificates'>
			<h1 className='text-center'>{t('certificates')}</h1>
			<div className="content">
				<MyCarousel>
					<img src={AAoH} alt="American Academy of Hypnosis" />
					<img src={HMTA} alt="Holistic Mind Therapy Completion" />
				</MyCarousel>
			</div>
		</section>
	)
}

export default Certificates