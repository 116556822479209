import {
	faInstagram,
	faTelegram,
	faTiktok,
	faWhatsapp,
	faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import './Contact.scss';

const Contact = () => {
	const { t } = useTranslation();

	return (
		<section className='contact'>
			<h1 className="text-center">{t('contact')}</h1>
			<div className="content">
				<div className="networks-container">
					<a href="mailto:info@sergej-heilsbringer.com" target="_blank" rel="noreferrer" style={{
						backgroundColor: "#224E771A",
					}} className="item">
						<FontAwesomeIcon style={{ color: "#224e77" }} icon={faEnvelope} />
						<span>E-Mail</span>
					</a>
					<a href="https://wa.me/41767463821" target="_blank" rel="noreferrer" style={{
						backgroundColor: "#2AB13F1A",
					}} className="item">
						<FontAwesomeIcon style={{ color: "#2AB13F" }} icon={faWhatsapp} />
						<span>WhatsApp</span>
					</a>
					<a href="https://t.me/sergejls" target="_blank" rel="noreferrer" style={{
						backgroundColor: "#30A7DF1A",
					}} className="item">
						<FontAwesomeIcon style={{ color: "#30A7DF" }} icon={faTelegram} />
						<span>Telegram</span>
					</a>
					<a href="https://www.instagram.com/sl.gipnocouch" target="_blank" rel="noreferrer" style={{
						backgroundColor: "#F608661A",
					}} className="item">
						<FontAwesomeIcon style={{ color: "#F60866" }} icon={faInstagram} />
						<span>Instagram</span>
					</a>
					<a href="https://www.youtube.com/@sergejlastowski3242" target="_blank" rel="noreferrer" style={{
						backgroundColor: "#FF00001A",
					}} className="item">
						<FontAwesomeIcon style={{ color: "#FF0000" }} icon={faYoutube} />
						<span>YouTube</span>
					</a>
					<a href="https://www.tiktok.com/@sergejlastowski" target="_blank" rel="noreferrer" style={{
						backgroundColor: "#1E30501A",
					}} className="item">
						<FontAwesomeIcon style={{ color: "#1E3050" }} icon={faTiktok} />
						<span>TikTok</span>
					</a>
				</div>
			</div>
		</section>
	)
}

export default Contact;