import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Preloader } from './Components';
import { Consult, Home, NotFound } from './Pages';

function App() {

	return (
		<Preloader>
			<BrowserRouter>
				<Routes>
					<Route index element={<Home />} />
					<Route path="consult" element={<Consult />} />
					<Route path="*" element={<NotFound />} />
				</Routes>
			</BrowserRouter>
		</Preloader>
	);
}

export default App;
