import { useTranslation } from 'react-i18next';
import { ConsultButton } from '../';

import './AboutProgram.scss';

const AboutProgram = () => {
	const { t } = useTranslation();

	return (
		<div className='about-program'>
			<h1 className='text-center'>{t('program_description')}</h1>
			<div className="content">
				<div className="text-container">
					<span>{t('approach')}</span>
					<span>{t('subconscious_mind')}</span>
					<span>{t('my_program_helped')}</span>
					<span>{t('first_step')}</span>
				</div>
			</div>
			<ConsultButton
				bg={"var(--green)"}
				color={"var(--blue)"}
			/>
		</div>
	)
}

export default AboutProgram