import {
	Body,
	Column,
	Container,
	Head,
	Heading,
	Html,
	Img,
	Preview,
	Row,
	Section,
	Text,
} from "@react-email/components";

const consultRequest = ({
	name,
	language,
	phone,
	email,
	message
}) => {

	if (language != null) {
		if (language === 'ru')
			language = 'Русский'
		else if (language === 'de')
			language = 'Deutsch'
	}

	return (
		<Html>
			<Head />
			<Preview>Sergej Heilsbringer</Preview>
			<Body style={main}>
				<Container>
					<Section style={content}>
						<Row>
							<Img
								width={70}
								src={"https://sergej-heilsbringer.com/android-chrome-512x512.png"}
								style={center}
								alt={'Sergej Heilsbringer Logo'}
							/>
						</Row>
						<Row>
							<Column>
								<Heading
									as="h2"
									style={{
										fontSize: 26,
										fontWeight: "bold",
										textAlign: "center",
										color: "#224e77",
									}}
								>
									Sie haben eine neue Anfrage!
								</Heading>

								{name &&
									<Text style={{ ...paragraph }}>
										<b>Name: </b> {name}
									</Text>
								}
								{language &&
									<Text style={{ ...paragraph }}>
										<b>Bevorzügte Sprache: </b>
										{language}
									</Text>
								}
								{phone &&
									<Text style={{ ...paragraph }}>
										<b>Telefonnummer: </b>
										{phone}
									</Text>
								}
								{email &&
									<Text style={{ ...paragraph }}>
										<b>E-Mail: </b>
										{email}
									</Text>
								}
								{message &&
									<Text style={{ ...paragraph }}>
										<b>Nachricht: </b>
										{message}
									</Text>
								}
							</Column>
						</Row>
					</Section>

					<Text
						style={{
							textAlign: "center",
							fontSize: 12,
							color: "#224e77",
							marginTop: 7
						}}
					>
						© 2024 <a style={{ ...a, color: "#224e77" }} href="https://sergej-heilsbringer.com">Sergej Heilsbringer</a>
					</Text>
				</Container>
			</Body>
		</Html>
	);
};

consultRequest.PreviewProps = {
	name: "Rostyslav",
	language: "de",
	phone: "0123456789",
	email: "example@gmail.com",
	message: "asd"
};

const main = {
	fontFamily:
		'-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
	color: "#224e77",
};

const center = {
	margin: "0 auto",
}

const a = {
	textDecoration: 'none !important',
	color: "#224e77",
}

const paragraph = {
	fontSize: "16px",
	lineHeight: "16px",
	color: "#224e77",
};

const content = {
	backgroundColor: "#ebfffe",
	border: "1px solid #afedea",
	borderRadius: "8px",
	overflow: "hidden",
	padding: "20px 20px 5px",
	color: "#224e77",
};

export default consultRequest;