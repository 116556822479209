import Carousel, { consts } from "@itseasy21/react-elastic-carousel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import './MyCarousel.scss';

const MyCarousel = ({children}) => {
	const myArrow = ({ type, onClick, isEdge }) => {
		return (
			<button
				className={`rec-arrow ${type === consts.PREV ? "left" : "right"}`}
				onClick={onClick}
				disabled={isEdge}
				tabIndex={0}
				aria-label={type === consts.PREV ? "left" : "right"}
			>
				{type === consts.PREV
					? <FontAwesomeIcon icon={faChevronLeft} />
					: <FontAwesomeIcon icon={faChevronRight} />
				}
			</button>
		)
	}

	const myPagination = ({ pages, activePage, onClick }) => {
		return (
			<div className='rec-pagination'>
				{pages.map(page => {
					return (
						<button
							key={page}
							onClick={() => onClick(page)}
							className={activePage === page ? "active" : ""}
							tabIndex={0}
							aria-label="pagination"
						></button>
					)
				})}
			</div>
		)
	}
	return (
		<Carousel className='carousel' renderArrow={myArrow} renderPagination={myPagination}>
			{children}
		</Carousel>
	)
}

export default MyCarousel