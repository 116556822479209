import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

import './ToTopButton.scss';

const ToTopButton = ({offset}) => {
	const [showButton, setShowButton] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > offset) {
				setShowButton(true);
			} else {
				setShowButton(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [offset]);

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	};

	return (
		<button aria-label='scroll to the top' className={`toTop-btn ${showButton ? 'show' : ''}`} onClick={scrollToTop}>
			<FontAwesomeIcon icon={faArrowUp} />
		</button>
	)
}

export default ToTopButton